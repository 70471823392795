.main {
  display: flex;
  flex-direction: column;
  margin: 15px;
}

.title {
  font-size: 1.2rem;
  font-weight: 500;
}

.imgContenedor {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
  min-width: 200px;
  min-height: 200px;
  height: auto;
  overflow: hidden;
}

.image {
  width: auto;
  height: auto;
  max-width: 50%;
  max-height: 50%;
  object-fit: contain;
  border: 1px solid var(--color-gray);
}

.markdownContainer {
  position: relative;
  min-width: 100%;
  height: inherit;
  overflow: auto;
  isolation: isolate;
}