.question {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.loading {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5rem;
}

.avatar {
  max-width: 100px;
}

.thought {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.bubble {
  border: 1px solid black;
  padding: 1rem 2rem;
  border-radius: 1rem;
  position: relative;
}

.bubble::after {
  content: " ";
  position: absolute;
  top: calc(50% - 5px);
  left: calc(100% - 5px);
  width: 10px;
  height: 10px;
  background-color: white;
  border-top: 1px solid black;
  border-right: 1px solid black;
  transform: rotate(45deg);
}

.spinner {
  width: 100%;
  height: 200px;
}

.answer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.response {
  margin: 20px auto;
  width: calc(100% - 40px);
  border: 1px solid black;
  padding: 1rem 2rem;
  border-radius: 1rem;
  position: relative;
  height: 100%;
  max-height: 100%;
  overflow: scroll;
}

.answerActions {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem 2rem;
}

.responseAvatar {
  max-width: 70px;
}