.main {
  padding: 1rem; 
}

.textarea {
  border-radius: 0.5rem; 
  border-width: 1px; 
  width: 100%; 
  resize: vertical; 
  margin-bottom: 2rem;
}

.button {
  width: 100%;
}