.main {
  padding: 1rem; 
  width: 100%;
  max-width: 950px;
}

.fields {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 1rem;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 1.5rem;
}

.card[data-drop="active"] {
  border: 4px dashed var(--color-primary-green);
  opacity: .5;
}

.title {
  margin-bottom: 1rem;
}

.input {
  display: none;
}

.buttons {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.textField {
  flex-grow: 1;
  max-width: 600px;
  width: 100%;
}

.textarea {
  border-radius: 0.5rem; 
  border-width: 1px; 
  width: 100%; 
}

.button {
  width: 100%;
}

.previewContainer {
  position: relative;
  width: 100%;
}

.preview {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 0.5rem;
}


@media (max-width: 800px) {

  .fields {
    flex-direction: column;
  }

  .imageField {
    width: 100%;
  }

  .textField {
    width: 100%;
  }
}